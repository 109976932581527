import { ToastId, useToast } from '@chakra-ui/react';
import { groupBy, sortBy, uniqueId } from 'lodash';
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useMutation, useQuery } from 'urql';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from '../components/common/BreadcrumbProvider';
import { useSheetViewer } from '../components/common/SheetViewer';
import {
  InitialShapeDrawingState,
  LineStringShapeDrawingResult,
  PointDrawingResult,
  PolygonShapeDrawingResult,
  ShapeDrawing,
  ShapeDrawingMode,
  ShapeDrawingResult,
  ShapeDrawingResultType,
  WedgesDrawingResult,
} from '../components/orderContractor/pdf-shapes/SheetShapeDrawing';
import {
  toMultiPolygon2,
  toPoints2,
} from '../domain/geometry/algorithms/util/type-mapping';
import {
  BulkUpsertSparkelPropertiesDocument,
  CreateOrderEntryFromTemplateDocument,
  CreateOrderEntryFromTemplateInput,
  CreateSheetShapeDocument,
  CreateSheetShapeInput,
  ForgeAttributeQueryTypeInput,
  GetOrderEntryTemplatesPageDocument,
  GetSheetShapesForProjectDeepDocument,
  MultiPolygon2Type,
  ProtanProjectStepEnum,
  ProtanProjectStepStatusEnum,
  SheetShapeDeepFragment,
  UpdateSheetShapeDocument,
  UpdateSheetShapeInput,
} from '../gql/graphql';
import { EventName, useMixpanel } from '../services/mixpanel';
import { getSheetShapeUrn } from '../services/viewer/SheetShapesManager';
import { useSelection } from '../services/viewer/selection';
import { useUserTenant } from '../services/auth-info';
import { Point2 } from '../domain/geometry/geometric-types';
import { findNextAvailableNameForCopy } from '../utils/naming-utils';
import { MoveOrCopySheetShapes } from '../components/orderContractor/pdf-shapes/MoveOrCopySheetShapes';
import { isShapeSelected } from '../components/orderContractor/ShapeItem';
import { useProtanProjectSteps } from '../components/common/ProtanProjectStepsProvider';
import {
  Diamond,
  KileTypeSKU,
} from '../components/orderContractor/pdf-shapes/SheetWedgeShapeDrawing';
import { GeometricResultVisualization } from '../services/viewer/result-visualisation/GeometricResultVisualization';
import { useShapes } from './shapes';
import { useOrderLabelsProtan } from './protan-data';
import { useResolvedOrder } from './order';
import {
  setProtanProperties,
  updateWedgeProps,
} from './protan-special-handling';
import { useDefaultAlgorithmVisualizations } from './default-algorithm-visualizations';
import { useAutoGeneratedRoof as useAutoGeneratedRoof } from './autogenerated-roofs-provider';
import { kileChoices } from 'src/components/orderContractor/ProductChoices';

type SheetShapeDrawingState =
  | {
      isDrawing: false;
      mode: undefined;
      hasResult: false;
    }
  | {
      isDrawing: true;
      mode: ShapeDrawingMode;
      // Opted for only exposing this to the outside world for now, but feel free to add the actual result if needed
      hasResult: boolean;
    };

export type SheetShapesContextValue = {
  drawing: SheetShapeDrawingState & {
    activate: (mode?: ShapeDrawingMode) => void;
    editShape: (shapeToEdit: string) => void;
    moveOrCopyShapes: (
      shapeIds: string[],
      translationVector: Point2,
      shouldCopy?: boolean
    ) => void;
    deactivate: () => void;
    submit: () => Promise<void>;
    submitError: Error | undefined;
    isSubmitting: boolean;
    shapeToEdit: string | null;
    isMovingShapes: boolean;
    setMovingShapes: (isMoving: boolean) => void;
    isCopyingShapes: boolean;
    setCopyingShapes: (isCopying: boolean) => void;
  };
  shapes: {
    data: SheetShapeDeepFragment[] | undefined;
    fetching: boolean;
    error: Error | undefined;
  };
};

const SheetShapesContext = createContext<SheetShapesContextValue | null>(null);

export const SheetShapesProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const { projectId } = useParams<{ projectId: string }>() as {
    projectId: string;
  };

  const [{ data: shapesData, error: shapesError, fetching: shapesFetching }] =
    useQuery({
      query: GetSheetShapesForProjectDeepDocument,
      variables: { projectId },
    });

  const [createShapeResult, createShapeMutation] = useMutation(
    CreateSheetShapeDocument
  );
  const [updateShapeResult, updateShapeMutation] = useMutation(
    UpdateSheetShapeDocument
  );

  const { tenant, isProtan } = useUserTenant();
  const { getNewShapeName } = useShapes(projectId);

  const shapes = useMemo(
    () =>
      shapesData?.project?.sheetShapes
        ? sortBy(shapesData?.project?.sheetShapes, (shape) => shape.createdAt)
        : null,
    [shapesData?.project?.sheetShapes]
  );

  const { operationResults } = useDefaultAlgorithmVisualizations(shapes || []);

  const [drawingModeKey, setDrawingModeKey] = useState(uniqueId());

  const toast = useToast();

  const {
    visibilityManager: {
      hideSheetShape,
      showSheetShape,
      addToIsolation,
      isolatedElements,
    },
    viewerRef,
    pageNumber,
    activeSheetId,
    shapesManager: { renderSheetShapes, unrenderSheetShapes },
    isDrawing: isDrawingShape,
    setIsDrawing: setDrawingShape,
    ghosting: { setGhostMode },
  } = useSheetViewer();

  const { deselectDbIds } = useSelection();

  const [shapeIdToEdit, setShapeIdToEdit] = useState<string | null>(null);

  const [isMovingShapes, setMovingShapes] = useState(false);
  const [isCopyingShapes, setCopyingShapes] = useState(false);
  const [drawingMode, setDrawingMode] = useState<ShapeDrawingMode>(
    ShapeDrawingMode.Rectangle
  );
  const [drawingResult, setDrawingResult] = useState<ShapeDrawingResult | null>(
    null
  );
  const [drawingInitialState, setDrawingInitialState] =
    useState<InitialShapeDrawingState[typeof drawingMode]>();

  const { t } = useTranslation('project');

  const shapeToEdit = shapes?.find((shape) => shape.id === shapeIdToEdit);

  const isSubmitting = createShapeResult.fetching || updateShapeResult.fetching;

  const hasDrawingResult = !!drawingResult && drawingResult.valid;

  const isMovingOrCopyingToastRef = useRef<ToastId>();

  const [, bulkCreateSparkelProps] = useMutation(
    BulkUpsertSparkelPropertiesDocument
  );

  const {
    qkast,
    fireRequirements,
    buildingHeight: roofHeight,
  } = useOrderLabelsProtan();

  const activateDrawing = useCallback(
    // eslint-disable-next-line complexity
    (newMode?: ShapeDrawingMode) => {
      setDrawingShape(true);

      if (newMode) {
        setDrawingMode(newMode);
        if (
          (newMode === ShapeDrawingMode.MagicPolygon ||
            newMode === ShapeDrawingMode.Polygon) &&
          drawingResult?.type === ShapeDrawingResultType.Polygon &&
          drawingResult.valid
        ) {
          setDrawingInitialState({
            multipolygon: drawingResult.multipolygon,
          });
        } else {
          setDrawingInitialState(undefined);
          setDrawingResult(null);
        }
      }
    },
    [drawingResult, setDrawingShape]
  );

  const deactivateDrawing = useCallback(() => {
    setShapeIdToEdit(null);
    setDrawingShape(false);
    setDrawingResult(null);
    setDrawingInitialState(undefined);
    setDrawingModeKey(uniqueId());
  }, [setDrawingShape]);

  const editShape = useCallback(
    (shapeId: string) => {
      const shapeToEdit = shapes?.find((shape) => shape.id === shapeId);
      if (!shapeToEdit) {
        throw new Error('Shape not found');
      }
      // Deselect to hide the shape being edited, because selected elements are visible even when hidden
      deselectDbIds({ [shapeToEdit.urn]: [shapeToEdit.dbId] });
      if (shapeToEdit.sheetShapePolygon) {
        setDrawingMode(ShapeDrawingMode.Polygon);
        setDrawingInitialState({
          multipolygon: toMultiPolygon2(
            shapeToEdit.sheetShapePolygon.multipolygon
          ),
        });
      } else if (shapeToEdit.sheetShapeLine) {
        setDrawingMode(ShapeDrawingMode.LineString);
        setDrawingInitialState({
          points: toPoints2(shapeToEdit.sheetShapeLine.points),
        });
      } else if (shapeToEdit.sheetShapePoint) {
        setDrawingMode(ShapeDrawingMode.Point);
        setDrawingInitialState({
          point: [
            [
              shapeToEdit.sheetShapePoint.point.x,
              shapeToEdit.sheetShapePoint.point.y,
            ],
          ],
          updating: true,
        });
      } else {
        throw new Error('Shape type not supported');
      }
      setDrawingShape(true);
      setShapeIdToEdit(shapeId);
    },
    [deselectDbIds, setDrawingShape, shapes]
  );

  const updateShape = useCallback(async () => {
    invariant(shapeToEdit, 'No shape is being edited');
    invariant(drawingResult, 'No drawing result');
    const input = getUpdateShapeInput(shapeToEdit, drawingResult);

    const result = await updateShapeMutation({
      input,
    });

    if (result.error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Could not update shape. Please try again',
      });
      throw result.error;
    } else {
      toast({
        title: `Updated shape ${shapeToEdit.name}`,
        status: 'success',
      });
      deactivateDrawing();
      setShapeIdToEdit(null);
      if (
        result.data?.updateSheetShape?.sheetShape?.dbId &&
        Object.values(isolatedElements).flat().length > 0
      ) {
        addToIsolation({
          [result.data.updateSheetShape.sheetShape.urn]: [
            result.data.updateSheetShape.sheetShape.dbId,
          ],
        });
      }
    }
  }, [
    addToIsolation,
    deactivateDrawing,
    drawingResult,
    isolatedElements,
    shapeToEdit,
    toast,
    updateShapeMutation,
  ]);

  const [, createOrderEntryFromTemplate] = useMutation(
    CreateOrderEntryFromTemplateDocument
  );

  let { orderId } = useParams<{
    orderId: string;
  }>() as { orderId: string };

  const [{ data: resourceTemplates }] = useQuery({
    query: GetOrderEntryTemplatesPageDocument,
  });

  const order = useResolvedOrder(projectId, orderId);

  const handleCreateFromTemplateClick = useCallback(
    async (
      templateId: string,
      title: string,
      query: ForgeAttributeQueryTypeInput | undefined,
      orderId: string
    ) => {
      const input: CreateOrderEntryFromTemplateInput = {
        orderEntryTemplateId: templateId,
        orderId,
        title,
        color: '#FFFFFF',
        query,
        dbids: null,
      };
      const result = await createOrderEntryFromTemplate({ input });
      if (result.error) {
        console.error(result.error);
        toast({
          status: 'error',
          position: 'top',
          title: 'An error occurred creating item from template',
        });
      } else {
        toast({
          status: 'success',
          position: 'top',
          title: 'Template item successfully created',
        });
      }
    },
    [createOrderEntryFromTemplate, toast]
  );

  // eslint-disable-next-line complexity
  const createWedgeShape = useCallback(
    // eslint-disable-next-line complexity
    async (
      shapeInput: CreateSheetShapeInput,
      diamond?: Diamond,
      kileType?: KileTypeSKU
    ) => {
      invariant(shapes, 'Shapes must be defined');
      invariant(drawingResult, 'Shapes must be defined');
      invariant(activeSheetId, 'Shapes must be defined');

      const shapeName = getNewShapeName(
        tenant?.group ?? '',
        drawingResult.type
      );

      const result = await createShapeMutation({ input: shapeInput });

      if (
        result.data?.createSheetShape?.sheetShape &&
        drawingResult.type === ShapeDrawingResultType.Wedge &&
        diamond &&
        kileType
      ) {
        updateWedgeProps(
          result.data,
          diamond,
          kileType,
          bulkCreateSparkelProps,
          projectId
        );
      }

      if (isProtan && result.data?.createSheetShape?.sheetShape) {
        setProtanProperties(
          result.data,
          roofHeight,
          qkast,
          orderId,
          fireRequirements,
          bulkCreateSparkelProps,
          projectId
        );
      }

      if (result.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not create shape. Please try again',
        });
        throw result.error;
      } else {
        if (!toast.isActive(`shape ${shapeName} created`)) {
          toast({
            id: `shape ${shapeName} created`,
            status: 'success',
            duration: 3000,
            title: `Created shape ${shapeName}`,
          });
        }
        deactivateDrawing();
        if (
          result.data?.createSheetShape?.sheetShape?.dbId &&
          Object.values(isolatedElements).flat().length > 0
        ) {
          addToIsolation({
            [result.data.createSheetShape.sheetShape.urn]: [
              result.data.createSheetShape.sheetShape.dbId,
            ],
          });
        }
      }
    },
    [
      activeSheetId,
      addToIsolation,
      bulkCreateSparkelProps,
      createShapeMutation,
      deactivateDrawing,
      drawingResult,
      fireRequirements,
      getNewShapeName,
      isProtan,
      isolatedElements,
      orderId,
      projectId,
      qkast,
      roofHeight,
      shapes,
      tenant?.group,
      toast,
    ]
  );

  /* eslint-disable complexity */
  const createShapes = useCallback(async () => {
    invariant(shapes, 'Shapes must be defined');
    invariant(drawingResult, 'Shapes must be defined');
    invariant(activeSheetId, 'Shapes must be defined');

    const shapeName = getNewShapeName(tenant?.group ?? '', drawingResult.type);

    const input = getCreateShapeInput(
      drawingResult,
      projectId,
      activeSheetId,
      pageNumber,
      shapeName
    );

    if (
      drawingResult.type === ShapeDrawingResultType.Wedge &&
      Array.isArray(input)
    ) {
      console.log('Array input', input);
      input.forEach((shape, index) =>
        createWedgeShape(
          shape,
          drawingResult.diamond[index],
          drawingResult.kileType[index]
        )
      );

      const uniqueKileSkus = new Set(drawingResult.kileType);

      uniqueKileSkus.forEach((sku) => {
        if (order.resolvedOrder?.orderEntries)
          kileChoices(
            sku,
            orderId,
            handleCreateFromTemplateClick,
            resourceTemplates,
            order.resolvedOrder.orderEntries
          );
      });

      return;
    }

    if (Array.isArray(input)) {
      input.forEach((shape) => createWedgeShape(shape));
    } else {
      await createWedgeShape(input);
    }
  }, [
    shapes,
    drawingResult,
    activeSheetId,
    getNewShapeName,
    tenant?.group,
    projectId,
    pageNumber,
    createWedgeShape,
    order.resolvedOrder?.orderEntries,
    orderId,
    handleCreateFromTemplateClick,
    resourceTemplates,
  ]);

  const getCopyShapeMutationInput = useCallback(
    (
      shape: SheetShapeDeepFragment,
      translationVector: Point2,
      newName: string
    ) => {
      if (shape.sheetShapePolygon) {
        const newMultipolygon: MultiPolygon2Type = JSON.parse(
          JSON.stringify(shape.sheetShapePolygon.multipolygon)
        );
        newMultipolygon.polygons.forEach((polygon) => {
          polygon.exterior.points.forEach((point) => {
            point.x += translationVector[0];
            point.y += translationVector[1];
          });
          polygon.interiors.forEach((interior) => {
            interior.points.forEach((point) => {
              point.x += translationVector[0];
              point.y += translationVector[1];
            });
          });
        });

        return getCreatePolygonInput(
          {
            valid: true,
            multipolygon: toMultiPolygon2(newMultipolygon),
          },
          projectId,
          shape.sheetId,
          shape.sheetPageNumber,
          newName,
          shape.folder ?? undefined
        );
      } else if (shape.sheetShapeLine) {
        const newPoints = shape.sheetShapeLine.points.map((point) => ({
          x: point.x + translationVector[0],
          y: point.y + translationVector[1],
        }));

        return getCreateLineInput(
          {
            valid: true,
            points: newPoints.map((point) => [point.x, point.y]),
          },
          projectId,
          shape.sheetId,
          shape.sheetPageNumber,
          newName,
          shape.folder ?? undefined
        );
      } else if (shape.sheetShapePoint) {
        return getCreatePointInput(
          {
            valid: true,
            points: [
              [
                shape.sheetShapePoint.point.x + translationVector[0],
                shape.sheetShapePoint.point.y + translationVector[1],
              ],
            ],
          },
          projectId,
          shape.sheetId,
          shape.sheetPageNumber,
          newName,
          shape.folder ?? undefined
        );
      }

      return null;
    },
    [projectId]
  );

  const moveOrCopyShapes = useCallback(
    // eslint-disable-next-line complexity
    async (
      shapeIds: string[],
      translationVector: Point2,
      shouldCopy = false
    ) => {
      setMovingShapes(false);
      setCopyingShapes(false);

      if (!shapes) {
        console.error('Shapes not loaded successfully');
        return;
      }

      let updatedExistingNames = shapes.map((shape) => shape.name);

      const shapeOperations = [];

      for (const shapeId of shapeIds) {
        const shapeToProcess = shapes.find((shape) => shape.id === shapeId);
        if (!shapeToProcess) {
          console.error('Shape not found');
          continue;
        }

        if (shouldCopy) {
          // Generate a unique new name for the copied shape
          const newName = findNextAvailableNameForCopy(
            shapeToProcess.name,
            updatedExistingNames
          );
          updatedExistingNames.push(newName); // Update the list of existing names to include this new name

          // Assume we have a function to prepare the shape data for copying
          // which adjusts the shape's position based on the translationVector and assigns the new name.
          const shapeCopyData = getCopyShapeMutationInput(
            shapeToProcess,
            translationVector,
            newName
          );

          if (shapeCopyData) {
            // Add the copy operation promise to the list
            shapeOperations.push(
              createShapeMutation({
                input: Array.isArray(shapeCopyData)
                  ? shapeCopyData[0]
                  : shapeCopyData,
              })
            );
          }
        } else {
          // For moving, update the shape's position based on the translationVector.
          // Assume getUpdateShapeData returns the necessary mutation input for the move.
          const shapeMoveData = getMoveShapeMutationInput(
            shapeToProcess,
            translationVector
          );

          if (shapeMoveData) {
            // Add the move operation promise to the list
            shapeOperations.push(updateShapeMutation({ input: shapeMoveData }));
          }
        }
      }

      isMovingOrCopyingToastRef.current = toast({
        title: shouldCopy ? t('shapes.copying') : t('shapes.moving'),
        status: 'info',
      });

      // Execute all shape operations (copy or move)
      try {
        await Promise.all(shapeOperations);
        toast({
          status: 'success',
          title: t('shapes.success.title'),
          description: shouldCopy
            ? t('shapes.success.copy')
            : t('shapes.success.move'),
        });
      } catch (error) {
        toast({
          status: 'error',
          title: t('shapes.error.title'),
          description: shouldCopy
            ? t('shapes.error.copy')
            : t('shapes.error.move'),
        });
        throw error;
        // Additional error handling logic could be implemented here, such as reverting partially completed operations
      } finally {
        toast.close(isMovingOrCopyingToastRef.current);
      }
    },
    [
      shapes,
      toast,
      t,
      getCopyShapeMutationInput,
      createShapeMutation,
      updateShapeMutation,
    ]
  );

  function getMoveShapeMutationInput(
    shape: SheetShapeDeepFragment,
    translationVector: Point2
  ) {
    if (shape.sheetShapePolygon) {
      const newMultipolygon: MultiPolygon2Type = JSON.parse(
        JSON.stringify(shape.sheetShapePolygon.multipolygon)
      ); // Deep copy to avoid mutation

      newMultipolygon.polygons.forEach((polygon) => {
        polygon.exterior.points.forEach((point) => {
          point.x += translationVector[0];
          point.y += translationVector[1];
        });
        polygon.interiors.forEach((interior) => {
          interior.points.forEach((point) => {
            point.x += translationVector[0];
            point.y += translationVector[1];
          });
        });
      });

      return getUpdatePolygonInput(shape, {
        valid: true,
        multipolygon: toMultiPolygon2(newMultipolygon),
      });
    } else if (shape.sheetShapeLine) {
      const newPoints = shape.sheetShapeLine.points.map((point) => ({
        x: point.x + translationVector[0],
        y: point.y + translationVector[1],
      }));

      return getUpdateLineInput(shape, {
        valid: true,
        points: newPoints.map((point) => [point.x, point.y]),
      });
    } else if (shape.sheetShapePoint) {
      return getUpdatePointInput(shape, {
        valid: true,
        points: [
          [
            shape.sheetShapePoint.point.x + translationVector[0],
            shape.sheetShapePoint.point.y + translationVector[1],
          ],
        ],
      });
    }

    return null;
  }

  const { projectName, orderName } = useBreadcrumb();

  const { trackEvent } = useMixpanel();

  const { projectSteps, setStepStatus } = useProtanProjectSteps();

  // Protan specific flow for drawing shapes.
  // If roof outline is in progress and drain placement is not started, set roof outline to completed and drain placement to in progress
  // If roof outline and drain placement is in progress, set drain placement to completed
  const handleProtanFlow = useCallback(() => {
    const roofOutlineStatus =
      projectSteps?.find(
        (step) => step.step === ProtanProjectStepEnum.RoofOutline
      )?.status || ProtanProjectStepStatusEnum.NotStarted;

    const drainPlacementStatus =
      projectSteps?.find(
        (step) => step.step === ProtanProjectStepEnum.DrainPlacement
      )?.status || ProtanProjectStepStatusEnum.NotStarted;

    if (
      roofOutlineStatus === ProtanProjectStepStatusEnum.InProgress &&
      drainPlacementStatus === ProtanProjectStepStatusEnum.NotStarted
    ) {
      setStepStatus(
        ProtanProjectStepEnum.RoofOutline,
        ProtanProjectStepStatusEnum.Completed
      );
      setStepStatus(
        ProtanProjectStepEnum.DrainPlacement,
        ProtanProjectStepStatusEnum.InProgress
      );
      activateDrawing(ShapeDrawingMode.Point);
    } else if (
      roofOutlineStatus === ProtanProjectStepStatusEnum.Completed &&
      drainPlacementStatus === ProtanProjectStepStatusEnum.InProgress
    ) {
      setGhostMode(1);

      setStepStatus(
        ProtanProjectStepEnum.DrainPlacement,
        ProtanProjectStepStatusEnum.Completed
      );

      // Set wedges to completed for now, but should be set to in progress when the user starts drawing the wedges
      setStepStatus(
        ProtanProjectStepEnum.WedgePlacement,
        ProtanProjectStepStatusEnum.Completed
      );
    }
  }, [activateDrawing, projectSteps, setGhostMode, setStepStatus]);

  //Some of below states can likely be isolated to the AutogeneratedRoof provider
  const {
    automaticDrainAndWedgesPlacement,
    pointsOnLine1,
    pointsOnLine2,
    segments1,
    segments2,
    diamondsLine1,
    diamondsLine2,
    kilerLine1,
    kilerLine2,
    handleSubmitDrains,
    handleSubmitWedges,
  } = useAutoGeneratedRoof();

  const roofOutlineInProgress =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.RoofOutline
    )?.status === ProtanProjectStepStatusEnum.InProgress;

  const submitDrawing = useCallback(async () => {
    if (!drawingResult || !drawingResult.valid) {
      throw new Error('No result to submit');
    }
    if (shapeIdToEdit) {
      await updateShape();
    } else {
      await createShapes();
      if (isProtan) {
        handleProtanFlow();
        if (automaticDrainAndWedgesPlacement && roofOutlineInProgress) {
          if (activeSheetId && kilerLine1 && kilerLine2 && resourceTemplates) {
            handleSubmitDrains(projectId, activeSheetId, pageNumber);
            handleSubmitWedges(
              segments1,
              projectId,
              activeSheetId,
              pageNumber,
              diamondsLine1,
              kilerLine1
            );
            handleSubmitWedges(
              segments2,
              projectId,
              activeSheetId,
              pageNumber,
              diamondsLine2,
              kilerLine2
            );
            if (orderId) {
              const uniqueKileSkus = new Set(kilerLine1.concat(kilerLine2));
              uniqueKileSkus.forEach((sku) => {
                if (order.resolvedOrder?.orderEntries)
                  kileChoices(
                    sku,
                    orderId,
                    handleCreateFromTemplateClick,
                    resourceTemplates,
                    order.resolvedOrder.orderEntries
                  );
              });
            }
            setStepStatus(
              ProtanProjectStepEnum.DrainPlacement,
              ProtanProjectStepStatusEnum.Completed
            );
            deactivateDrawing();
          }
        }
      }

      trackEvent(EventName.ShapeCreated, {
        'Viewer Mode': 'sheets',
        'Shape Type': drawingResult.type,
        'Project Name': projectName,
        'Project ID': projectId,
        'Table Name': orderName,
      });
    }
  }, [
    drawingResult,
    shapeIdToEdit,
    updateShape,
    createShapes,
    isProtan,
    trackEvent,
    projectName,
    projectId,
    orderName,
    handleProtanFlow,
    automaticDrainAndWedgesPlacement,
    roofOutlineInProgress,
    activeSheetId,
    kilerLine1,
    kilerLine2,
    resourceTemplates,
    handleSubmitDrains,
    pageNumber,
    handleSubmitWedges,
    segments1,
    diamondsLine1,
    segments2,
    diamondsLine2,
    orderId,
    setStepStatus,
    deactivateDrawing,
    order.resolvedOrder?.orderEntries,
    handleCreateFromTemplateClick,
  ]);

  useEffect(() => {
    if (shapes) {
      const shapesForSheetAndPage = shapes
        .filter(
          (shape) =>
            shape.sheetId === activeSheetId &&
            shape.sheetPageNumber === pageNumber
        )
        .sort((a) => (a.sheetShapePoint ? 1 : -1)); // Points should be rendered last
      const shapesByUrn = groupBy(shapesForSheetAndPage, (shape) => shape.urn);
      for (const urn of Object.keys(shapesByUrn)) {
        renderSheetShapes(urn, shapesByUrn[urn]);
      }
      return () => {
        for (const urn of Object.keys(shapesByUrn)) {
          unrenderSheetShapes(urn);
        }
      };
    }
  }, [
    activeSheetId,
    renderSheetShapes,
    pageNumber,
    shapes,
    unrenderSheetShapes,
  ]);

  // Hook for deactivating drawing if the shape to edit is deleted
  useEffect(() => {
    if (shapes !== null) {
      const shapeIds = shapes.map((shape) => shape.id);
      if (
        isDrawingShape &&
        shapeIdToEdit &&
        !shapeIds.includes(shapeIdToEdit)
      ) {
        deactivateDrawing();
      }
    }
  }, [deactivateDrawing, isDrawingShape, shapeIdToEdit, shapes]);

  // Hook for hiding the shape that is being edited
  useEffect(() => {
    if (shapeToEdit && isDrawingShape) {
      hideSheetShape(shapeToEdit.urn, shapeToEdit.dbId);
      return () => {
        showSheetShape(shapeToEdit.urn, shapeToEdit.dbId);
      };
    }
  }, [isDrawingShape, shapeToEdit, hideSheetShape, showSheetShape]);

  // Hook for setting cursor when moving or copying shapes
  useEffect(() => {
    const moveOrCopyCursor = `url(/src/assets/icons/${
      isCopyingShapes ? 'copy' : 'move'
    }-shape-icon.png), crosshair`;

    if (isMovingShapes || isCopyingShapes) {
      viewerRef?.current?.style.setProperty('cursor', moveOrCopyCursor);
    } else {
      viewerRef?.current?.style.removeProperty('cursor');
    }
  }, [isCopyingShapes, isMovingShapes, viewerRef]);

  const drawingState: SheetShapeDrawingState = useMemo(() => {
    if (!isDrawingShape) {
      return {
        isDrawing: false,
        hasResult: false,
      };
    } else {
      return {
        isDrawing: true,
        hasResult: hasDrawingResult,
        mode: drawingMode,
      };
    }
  }, [drawingMode, hasDrawingResult, isDrawingShape]);

  const contextValue = useMemo<SheetShapesContextValue>(
    () => ({
      drawing: {
        activate: activateDrawing,
        editShape,
        moveOrCopyShapes,
        deactivate: deactivateDrawing,
        submit: submitDrawing,
        submitError: createShapeResult.error || updateShapeResult.error,
        isSubmitting,
        shapeToEdit: shapeIdToEdit,
        isMovingShapes: isMovingShapes,
        setMovingShapes: setMovingShapes,
        isCopyingShapes: isCopyingShapes,
        setCopyingShapes: setCopyingShapes,
        ...drawingState,
      },
      shapes: {
        data: shapesData?.project?.sheetShapes,
        fetching: shapesFetching,
        error: shapesError,
      },
    }),
    [
      activateDrawing,
      editShape,
      moveOrCopyShapes,
      deactivateDrawing,
      submitDrawing,
      createShapeResult.error,
      updateShapeResult.error,
      isSubmitting,
      shapeIdToEdit,
      isMovingShapes,
      isCopyingShapes,
      drawingState,
      shapesData?.project?.sheetShapes,
      shapesFetching,
      shapesError,
    ]
  );

  const { selectedSheetShapes } = useSelection();

  const selectedShapes = useMemo(
    () =>
      shapes?.filter((shape) => isShapeSelected(shape, selectedSheetShapes)) ??
      [],
    [selectedSheetShapes, shapes]
  );

  const onMoveOrCopyShapeResult = useCallback(
    (translationVector: Point2) =>
      moveOrCopyShapes(
        selectedShapes.map((shape) => shape.id) as string[],
        translationVector,
        isCopyingShapes
      ),
    [isCopyingShapes, moveOrCopyShapes, selectedShapes]
  );

  return (
    <SheetShapesContext.Provider value={contextValue}>
      {children}
      {isDrawingShape ? (
        <ShapeDrawing
          // https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
          key={drawingModeKey}
          mode={drawingMode}
          onResult={setDrawingResult}
          initialState={drawingInitialState}
        />
      ) : null}
      {viewerRef && (isMovingShapes || isCopyingShapes) ? (
        <MoveOrCopySheetShapes
          shapes={selectedShapes}
          onResult={onMoveOrCopyShapeResult}
        />
      ) : null}
      <GeometricResultVisualization operationResults={operationResults} />
    </SheetShapesContext.Provider>
  );
};

export const useSheetShapes = () => {
  const context = useContext(SheetShapesContext);
  if (!context) {
    throw new Error('useShapesContext must be used within a ShapesProvider');
  }
  return context;
};

const getUpdateShapeInput = (
  shapeToEdit: SheetShapeDeepFragment,
  drawingResult: ShapeDrawingResult
): UpdateSheetShapeInput => {
  switch (drawingResult.type) {
    case ShapeDrawingResultType.Polygon:
      return getUpdatePolygonInput(shapeToEdit, drawingResult);
    case ShapeDrawingResultType.LineString:
      return getUpdateLineInput(shapeToEdit, drawingResult);
    case ShapeDrawingResultType.Wedge:
      return getUpdateLineInput(shapeToEdit, drawingResult);
    case ShapeDrawingResultType.Point:
      return getUpdatePointInput(shapeToEdit, drawingResult);
  }
};

const getMultipolygonFromDrawingResult = (
  drawingResult: PolygonShapeDrawingResult
): MultiPolygon2Type => {
  invariant(drawingResult);
  invariant(drawingResult.valid);
  return {
    polygons: drawingResult.multipolygon.polygons.map((polygon) => ({
      exterior: {
        points: polygon.exterior.map((point) => ({
          x: point[0],
          y: point[1],
        })),
      },
      interiors: polygon.interiors.map((interior) => ({
        points: interior.map((point) => ({
          x: point[0],
          y: point[1],
        })),
      })),
    })),
  };
};

const getUpdatePolygonInput = (
  shapeToEdit: SheetShapeDeepFragment,
  drawingResult: PolygonShapeDrawingResult
): UpdateSheetShapeInput => {
  invariant(shapeToEdit, 'Shape to edit is null');
  invariant(drawingResult);
  invariant(drawingResult.valid);

  return {
    id: shapeToEdit.id,
    patch: {
      sheetShapePolygon: {
        updateById: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: shapeToEdit.sheetShapePolygon!.id,
          patch: {
            multipolygon: getMultipolygonFromDrawingResult(drawingResult),
          },
        },
      },
    },
  };
};

const getUpdateLineInput = (
  shapeToEdit: SheetShapeDeepFragment,
  drawingResult: LineStringShapeDrawingResult
): UpdateSheetShapeInput => {
  invariant(shapeToEdit, 'Shape to edit is null');
  return {
    id: shapeToEdit.id,
    patch: {
      sheetShapeLine: {
        updateById: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: shapeToEdit.sheetShapeLine!.id,
          patch: {
            points: drawingResult.points.map((point) => ({
              x: point[0],
              y: point[1],
            })),
          },
        },
      },
    },
  };
};

const getUpdatePointInput = (
  shapeToEdit: SheetShapeDeepFragment,
  drawingResult: PointDrawingResult
): UpdateSheetShapeInput => {
  invariant(shapeToEdit, 'Shape to edit is null');
  invariant(drawingResult.points[0], 'Point is null');
  return {
    id: shapeToEdit.id,
    patch: {
      sheetShapePoint: {
        updateById: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: shapeToEdit.sheetShapePoint!.id,
          patch: {
            point: {
              x: drawingResult.points[0][0],
              y: drawingResult.points[0][1],
            },
          },
        },
      },
    },
  };
};

function getCreateShapeInput(
  drawingResult: ShapeDrawingResult,
  projectId: string,
  sheetId: string,
  sheetPageNumber: number,
  shapeName: string
): CreateSheetShapeInput | CreateSheetShapeInput[] {
  switch (drawingResult.type) {
    case ShapeDrawingResultType.Polygon:
      return getCreatePolygonInput(
        drawingResult as PolygonShapeDrawingResult,
        projectId,
        sheetId,
        sheetPageNumber,
        shapeName
      );
    case ShapeDrawingResultType.LineString:
      return getCreateLineInput(
        drawingResult as LineStringShapeDrawingResult,
        projectId,
        sheetId,
        sheetPageNumber,
        shapeName
      );
    case ShapeDrawingResultType.Point:
      return getCreatePointInput(
        drawingResult as PointDrawingResult,
        projectId,
        sheetId,
        sheetPageNumber,
        shapeName
      );
    case ShapeDrawingResultType.Wedge:
      return getCreateWedgeInput(
        drawingResult as WedgesDrawingResult,
        projectId,
        sheetId,
        sheetPageNumber,
        shapeName
      );
  }
}

const getCreatePolygonInput = (
  drawingResult: PolygonShapeDrawingResult,
  projectId: string,
  sheetId: string,
  sheetPageNumber: number,
  shapeName: string,
  folderId?: string
): CreateSheetShapeInput => {
  invariant(drawingResult);
  invariant(drawingResult.valid);
  return {
    sheetShape: {
      projectId,
      sheetId,
      urn: getSheetShapeUrn(projectId),
      sheetPageNumber,
      name: shapeName,
      sheetShapePolygon: {
        create: [
          {
            multipolygon: getMultipolygonFromDrawingResult(drawingResult),
          },
        ],
      },
    },
  };
};

const getCreateLineInput = (
  drawingResult: LineStringShapeDrawingResult,
  projectId: string,
  sheetId: string,
  sheetPageNumber: number,
  shapeName: string,
  folderId?: string
): CreateSheetShapeInput => {
  return {
    sheetShape: {
      projectId,
      sheetId,
      urn: getSheetShapeUrn(projectId),
      sheetPageNumber,
      name: shapeName,
      sheetShapeLine: {
        create: [
          {
            points: drawingResult.points.map((point) => ({
              x: point[0],
              y: point[1],
            })),
          },
        ],
      },
    },
  };
};

const getCreateWedgeInput = (
  drawingResult: WedgesDrawingResult,
  projectId: string,
  sheetId: string,
  sheetPageNumber: number,
  shapeName: string,
  folderId?: string
): CreateSheetShapeInput[] => {
  const lineSegments: Point2[][] = drawingResult.points
    .map((point, index) => {
      if (index < drawingResult.points.length - 1) {
        return [drawingResult.points[index], drawingResult.points[index + 1]];
      }
      return null;
    })
    .filter((segment): segment is Point2[] => segment !== null);

  return lineSegments.map((lineSegment) => {
    return {
      sheetShape: {
        projectId,
        sheetId,
        urn: getSheetShapeUrn(projectId),
        sheetPageNumber,
        name: shapeName,
        sheetShapeLine: {
          create: [
            {
              points: [
                { x: lineSegment[0][0], y: lineSegment[0][1] },
                { x: lineSegment[1][0], y: lineSegment[1][1] },
              ],
            },
          ],
        },
      },
    };
  });
};

export const getCreatePointInput = (
  drawingResult: PointDrawingResult,
  projectId: string,
  sheetId: string,
  sheetPageNumber: number,
  shapeName: string,
  folderId?: string
): CreateSheetShapeInput[] => {
  invariant(drawingResult.points, 'Point is null');

  return drawingResult.points.map((point) => {
    return {
      sheetShape: {
        projectId,
        sheetId,
        urn: getSheetShapeUrn(projectId),
        sheetPageNumber,
        name: shapeName,
        sheetShapePoint: {
          create: [
            {
              point: {
                x: point[0],
                y: point[1],
              },
            },
          ],
        },
      },
    };
  });
};
