import { memoize } from 'lodash';
import {
  Source,
  empty,
  fromArray,
  fromPromise,
  make,
  map,
  merge,
  mergeMap,
  pipe,
} from 'wonka';
import {
  ModelsMap,
  ShapesMap,
  SheetCalibrationsMap,
  SheetShapesMap,
  SparkelPropertiesMap,
} from '../components/common/ForgeViewer';
import {
  ElementIdentifierDbidType,
  ShapeDeepFragment,
  SheetScaleType,
  SheetShapeDeepFragment,
} from '../gql/graphql';
import {
  CustomElementClass,
  GEOMETRIC_PROPERTY_TO_ALGORITHM_MAP,
  IfcClass,
  RevitCategory,
} from './geometry/algorithm-map';

import { getGeometricAlgorithmForType } from './geometry/algorithm-selector';
import {
  CornerMultiPolygon2Algorithm,
  ExtrudedPolygonAlgorithm,
  GeometricAlgorithmType,
  MeshAlgorithm,
  MeshAlgorithmType,
  MultiPolygon2Algorithm,
  MultiPolygon2AlgorithmType,
  MultiPolygonAlgorithm,
  ParameterizedMultiPolygon2Algorithm,
  Polyline2Algorithm,
  PolylineAlgorithm,
  RandMultiPolygon2Algorithm,
  isExtrudedPolygonAlgorithm,
  isMeshAlgorithm,
  isPolygon2Algorithm,
  isPolygonAlgorithm,
  isPolyline2Algorithm,
  isPolylineAlgorithm,
} from './geometry/algorithm-types';
import { getScaledValue } from './geometry/algorithms/util';
import {
  toMultiPolygon,
  toMultiPolygon2,
  toPlane,
  toPoints,
  toPoints2,
} from './geometry/algorithms/util/type-mapping';
import { getTriangles } from './geometry/extract-triangles';
import {
  GeometricProperty,
  GeometricResult,
  OperationContext,
  OperationResult,
  dimensionsForResultType,
  isGeometricProperty,
} from './geometry/geometric-types';
import { PropertyContext } from './property-operations';
import { getUnitScaleFromCalibration } from './sheet-calibration';
import { getWorkerPool } from './worker/worker-pool';

export const calculateGeometry = async (
  geometricPropertyOrAlgorithm: GeometricProperty | GeometricAlgorithmType,
  dbIds: ElementIdentifierDbidType[],
  propertyContext: PropertyContext,
  maxTrianglesForComplexAlgorithm: number | undefined
): Promise<OperationResult[]> => {
  const {
    loadedModels,
    loadedShapes,
    loadedSheetShapes,
    loadedSheetCalibrations,
    loadedSparkelProperties,
  } = propertyContext;
  const { bimElementDbIds, shapeDbIds, sheetShapeDbIds } = splitDbIdsByType(
    dbIds,
    loadedShapes,
    loadedModels,
    loadedSheetShapes
  );

  const bimResults = await calculateGeometryForMeshElements(
    geometricPropertyOrAlgorithm,
    bimElementDbIds,
    loadedModels,
    maxTrianglesForComplexAlgorithm
  );

  const shapeResults = calculateGeometryForShapes(
    geometricPropertyOrAlgorithm,
    shapeDbIds,
    loadedShapes
  );

  const sheetShapeResults = calculateGeometryForSheetShapes(
    geometricPropertyOrAlgorithm,
    sheetShapeDbIds,
    loadedSheetShapes,
    loadedSheetCalibrations,
    loadedSparkelProperties
  );

  return [...bimResults, ...shapeResults, ...sheetShapeResults];
};

function splitDbIdsByType(
  dbIds: ElementIdentifierDbidType[],
  loadedShapes: ShapesMap,
  loadedModels: ModelsMap,
  loadedSheetShapes: SheetShapesMap
) {
  const shapeDbIds: ElementIdentifierDbidType[] = [];
  const sheetShapeDbIds: ElementIdentifierDbidType[] = [];
  const bimElementDbIds: ElementIdentifierDbidType[] = [];
  dbIds.forEach((dbId) => {
    if (dbId.modelUrn in loadedShapes) {
      shapeDbIds.push(dbId);
    } else if (dbId.modelUrn in loadedModels) {
      bimElementDbIds.push(dbId);
    } else if (dbId.modelUrn in loadedSheetShapes) {
      sheetShapeDbIds.push(dbId);
    }
  });
  return { bimElementDbIds, shapeDbIds, sheetShapeDbIds };
}

async function calculateGeometryForMeshElements(
  geometricPropertyOrAlgorithm: GeometricProperty | GeometricAlgorithmType,
  meshElementDbIds: ElementIdentifierDbidType[],
  loadedModels: ModelsMap,
  maxTrianglesForComplexAlgorithm: number | undefined
): Promise<OperationResult[]> {
  const dbidsWithCategories = await getCategories(
    loadedModels,
    meshElementDbIds
  );
  const results: OperationResult[] = [];
  for (const dbId of dbidsWithCategories) {
    const model = loadedModels[dbId.modelUrn];
    const algorithmType = getAlgorithmTypeForBimElement(
      geometricPropertyOrAlgorithm,
      dbId
    );

    if (model && algorithmType) {
      try {
        const result = await calculateGeometricAlgorithmForMeshElement(
          algorithmType,
          dbId,
          model,
          maxTrianglesForComplexAlgorithm
        );
        if (result) {
          results.push(result);
        }
      } catch (error: unknown) {
        //Don't add result to result list if error and log. Triggers missing quantity warnings.
        console.error('Geometric calculation failed for DB id ', dbId, error);
      }
    }
  }
  return results;
}

function calculateGeometryForShapes(
  geometricPropertyOrAlgorithm: GeometricProperty | GeometricAlgorithmType,
  shapeDbIds: ElementIdentifierDbidType[],
  loadedShapes: ShapesMap
): OperationResult[] {
  const results: OperationResult[] = [];
  for (const dbId of shapeDbIds) {
    const shapes = loadedShapes[dbId.modelUrn]?.filter((shape) =>
      dbId.dbIds.includes(shape.dbId)
    );
    if (!shapes) {
      continue;
    }

    for (let shape of shapes) {
      const algorithmType = getAlgorithmTypeForShape(
        geometricPropertyOrAlgorithm,
        shape
      );
      if (!algorithmType) {
        continue;
      }
      const result = calculateGeometricAlgorithmForShape(
        algorithmType,
        dbId,
        shape
      );
      if (result) {
        results.push(result);
      }
    }
  }
  return results;
}

function calculateGeometryForSheetShapes(
  geometricPropertyOrAlgorithm: GeometricProperty | GeometricAlgorithmType,
  sheetShapeDbIds: ElementIdentifierDbidType[],
  loadedSheetShapes: SheetShapesMap,
  loadedSheetCalibrations: SheetCalibrationsMap,
  loadedSparkelProperties: SparkelPropertiesMap
): OperationResult[] {
  const results: OperationResult[] = [];
  for (const dbId of sheetShapeDbIds) {
    const shapes = loadedSheetShapes[dbId.modelUrn]?.filter((shape) =>
      dbId.dbIds.includes(shape.dbId)
    );
    if (!shapes) {
      continue;
    }

    const externalShapes = loadedSheetShapes[dbId.modelUrn];

    for (let shape of shapes) {
      const calibrationForShape = loadedSheetCalibrations[shape.sheetId]?.find(
        (calibration) => calibration.pageNumber === shape.sheetPageNumber
      )?.calibration;

      const algorithmType = getAlgorithmTypeForSheetShape(
        geometricPropertyOrAlgorithm,
        shape
      );
      if (!algorithmType || !calibrationForShape) {
        continue;
      }

      const result = calculateGeometricAlgorithmForSheetShape(
        algorithmType,
        dbId,
        shape,
        calibrationForShape,
        loadedSparkelProperties,
        externalShapes
      );
      if (result) {
        results.push(result);
      }
    }
  }
  return results;
}

// Don't calculate geometric algorithms for elements with a high number of triangles
// As these elements are typically composite/complex elements users are not interested in
// and they can cause the application to become unresponsive
const maxTrianglesForMeshElement = 50000;
const calculateGeometricAlgorithmForMeshElement = memoize(
  async (
    algorithmType: GeometricAlgorithmType,
    dbId: DbIdWithIfcClass | DbIdWithRevitCategory,
    model: Autodesk.Viewing.Model,
    maxTrianglesForComplexAlgorithm: number | undefined
  ): Promise<OperationResult | undefined> => {
    if (!isMeshAlgorithm(algorithmType)) {
      return;
    }
    let targetType: IfcClass | RevitCategory;
    if ((dbId as DbIdWithIfcClass).ifcClass) {
      targetType = (dbId as DbIdWithIfcClass).ifcClass;
    } else if ((dbId as DbIdWithRevitCategory).revitCategory) {
      targetType = (dbId as DbIdWithRevitCategory).revitCategory;
    } else {
      return;
    }

    const context: OperationContext = {
      dbId: dbId.dbId,
      targetType,
      unitScale: model.getUnitScale(),
      modelUrn: dbId.modelUrn,
      algorithmType: algorithmType,
    };
    const trianglesForDbId = getTriangles(model, dbId.dbId);
    if (trianglesForDbId.length > maxTrianglesForMeshElement) {
      return [undefined, context];
    }
    const pool = getWorkerPool();
    if (!pool) {
      const algorithm = getGeometricAlgorithmForType(
        algorithmType,
        maxTrianglesForComplexAlgorithm
      ) as MeshAlgorithm;
      const result = algorithm(trianglesForDbId);
      return toOperationResult(result, context);
    }
    try {
      const result = await pool.queue((worker) =>
        worker.evaluateMeshAlgorithm(trianglesForDbId, algorithmType)
      );
      return toOperationResult(result, context);
    } catch (err: unknown) {
      throw new Error('Worker task failed', err as Error);
    }
  },
  (algorithmType, dbId) => `${JSON.stringify(dbId)}-${algorithmType}`
);

function calculateGeometricAlgorithmForShape(
  algorithmType: GeometricAlgorithmType,
  dbId: ElementIdentifierDbidType,
  shape: ShapeDeepFragment
): OperationResult | undefined {
  const { polygon, extrudedPolygon, line } = shape;

  if (polygon && isPolygonAlgorithm(algorithmType)) {
    const algorithm = getGeometricAlgorithmForType(
      algorithmType
    ) as MultiPolygonAlgorithm;
    const { plane, multipolygon, unitScale } = polygon;
    const context: OperationContext = {
      dbId: shape.dbId,
      targetType: CustomElementClass.PolygonShape,
      unitScale,
      modelUrn: dbId.modelUrn,
      algorithmType,
    };

    const result = algorithm(toMultiPolygon(multipolygon), toPlane(plane));
    return toOperationResult(result, context);
  } else if (extrudedPolygon && isExtrudedPolygonAlgorithm(algorithmType)) {
    const algorithm = getGeometricAlgorithmForType(
      algorithmType
    ) as ExtrudedPolygonAlgorithm;
    const { plane, multipolygon, unitScale, thickness } = extrudedPolygon;
    const context: OperationContext = {
      dbId: shape.dbId,
      targetType: CustomElementClass.ExtrudedPolygonShape,
      unitScale,
      modelUrn: dbId.modelUrn,
      algorithmType,
    };

    const result = algorithm(
      toMultiPolygon(multipolygon),
      toPlane(plane),
      thickness
    );
    return toOperationResult(result, context);
  } else if (line && isPolylineAlgorithm(algorithmType)) {
    const algorithm = getGeometricAlgorithmForType(
      algorithmType
    ) as PolylineAlgorithm;

    const { points, unitScale } = line;
    const context: OperationContext = {
      dbId: shape.dbId,
      targetType: CustomElementClass.LineShape,
      unitScale,
      modelUrn: dbId.modelUrn,
      algorithmType,
    };
    const result = algorithm(toPoints(points));
    return toOperationResult(result, context);
  }
}

// eslint-disable-next-line complexity
function calculateGeometricAlgorithmForSheetShape(
  algorithmType: GeometricAlgorithmType,
  dbId: ElementIdentifierDbidType,
  shape: SheetShapeDeepFragment,
  calibration: SheetScaleType,
  loadedSparkelProperties: SparkelPropertiesMap,
  externalShapes: SheetShapeDeepFragment[]
): OperationResult | undefined {
  //Parameterize here, remember units. Roof parameters.
  //Make sure to split to seperate components
  //Maybe super specialcase protan here?? Not that many shapes, can afford one network request per element. Not that many

  const { sheetShapePolygon: polygon, sheetShapeLine: line } = shape;

  if (
    polygon &&
    isPolygon2Algorithm(algorithmType) &&
    algorithmType !== MultiPolygon2AlgorithmType.POLYGON2_FIRE_SECTIONING &&
    algorithmType !== MultiPolygon2AlgorithmType.POLYGON2_CORNER_AREA &&
    algorithmType !== MultiPolygon2AlgorithmType.POLYGON2_RAND_AREA
  ) {
    const algorithm = getGeometricAlgorithmForType(
      algorithmType
    ) as MultiPolygon2Algorithm;
    const { multipolygon } = polygon;
    const context: OperationContext = {
      dbId: shape.dbId,
      targetType: CustomElementClass.Polygon2Shape,
      unitScale: getUnitScaleFromCalibration(calibration),
      modelUrn: dbId.modelUrn,
      algorithmType,
    };

    const result = algorithm(toMultiPolygon2(multipolygon));
    return toOperationResult(result, context);
  } else if (line && isPolyline2Algorithm(algorithmType)) {
    const algorithm = getGeometricAlgorithmForType(
      algorithmType
    ) as Polyline2Algorithm;

    const { points } = line;
    const context: OperationContext = {
      dbId: shape.dbId,
      targetType: CustomElementClass.Line2Shape,
      unitScale: getUnitScaleFromCalibration(calibration),
      modelUrn: dbId.modelUrn,
      algorithmType,
    };
    const result = algorithm(toPoints2(points));
    return toOperationResult(result, context);
  } else if (
    polygon &&
    algorithmType === MultiPolygon2AlgorithmType.POLYGON2_FIRE_SECTIONING
  ) {
    //Better handling above, can likely be refactored to a switch
    const algorithm = getGeometricAlgorithmForType(
      algorithmType
    ) as ParameterizedMultiPolygon2Algorithm;

    const { multipolygon } = polygon;
    const context: OperationContext = {
      dbId: shape.dbId,
      targetType: CustomElementClass.Polygon2Shape,
      unitScale: getUnitScaleFromCalibration(calibration),
      modelUrn: dbId.modelUrn,
      algorithmType,
    };

    const relevantSparkelProperties = loadedSparkelProperties[dbId.modelUrn];
    let fireSectioningBuffer = 0;

    if (relevantSparkelProperties) {
      const bufferFromProps = relevantSparkelProperties.find(
        (prop) =>
          prop.propertySet === 'Fire sectioning' && prop.dbId === shape.dbId
      )?.propertyValue;
      if (bufferFromProps) {
        fireSectioningBuffer = Number(bufferFromProps);
      }
    }

    const result = algorithm(
      toMultiPolygon2(multipolygon),
      Number(fireSectioningBuffer / context.unitScale),
      externalShapes,
      getUnitScaleFromCalibration(calibration)
    );
    return toOperationResult(result, context);
  } else if (
    polygon &&
    algorithmType === MultiPolygon2AlgorithmType.POLYGON2_CORNER_AREA
  ) {
    //Better handling above
    const algorithm = getGeometricAlgorithmForType(
      algorithmType
    ) as CornerMultiPolygon2Algorithm;

    const { multipolygon } = polygon;
    const context: OperationContext = {
      dbId: shape.dbId,
      targetType: CustomElementClass.Polygon2Shape,
      unitScale: getUnitScaleFromCalibration(calibration),
      modelUrn: dbId.modelUrn,
      algorithmType,
    };

    const relevantSparkelProperties = loadedSparkelProperties[dbId.modelUrn];
    let roofHeight;

    if (relevantSparkelProperties) {
      const bufferFromProps = relevantSparkelProperties.find(
        (prop) => prop.propertySet === 'Roof height' && prop.dbId === shape.dbId
      )?.propertyValue;
      if (bufferFromProps) {
        roofHeight = Number(bufferFromProps);
      }
    }

    const result = algorithm(
      toMultiPolygon2(multipolygon),
      roofHeight,
      context.unitScale
    );
    return toOperationResult(result, context);
  } else if (
    polygon &&
    algorithmType === MultiPolygon2AlgorithmType.POLYGON2_RAND_AREA
  ) {
    //Better handling above
    const algorithm = getGeometricAlgorithmForType(
      algorithmType
    ) as RandMultiPolygon2Algorithm;

    const { multipolygon } = polygon;
    const context: OperationContext = {
      dbId: shape.dbId,
      targetType: CustomElementClass.Polygon2Shape,
      unitScale: getUnitScaleFromCalibration(calibration),
      modelUrn: dbId.modelUrn,
      algorithmType,
    };

    const relevantSparkelProperties = loadedSparkelProperties[dbId.modelUrn];
    let roofHeight;

    if (relevantSparkelProperties) {
      const bufferFromProps = relevantSparkelProperties.find(
        (prop) => prop.propertySet === 'Roof height' && prop.dbId === shape.dbId
      )?.propertyValue;
      if (bufferFromProps) {
        roofHeight = Number(bufferFromProps);
      }
    }

    const result = algorithm(
      toMultiPolygon2(multipolygon),
      roofHeight,
      context.unitScale
    );
    return toOperationResult(result, context);
  }
}

function getAlgorithmTypeForBimElement(
  geometricPropertyOrAlgorithm: GeometricProperty | GeometricAlgorithmType,
  dbId: DbIdWithIfcClass | DbIdWithRevitCategory
): GeometricAlgorithmType | undefined {
  if (isGeometricProperty(geometricPropertyOrAlgorithm)) {
    // Check if the dbId is an IFC class or a Revit category
    if ((dbId as DbIdWithIfcClass).ifcClass !== undefined) {
      return GEOMETRIC_PROPERTY_TO_ALGORITHM_MAP.get(
        (dbId as DbIdWithIfcClass).ifcClass
      )?.get(geometricPropertyOrAlgorithm);
    } else if ((dbId as DbIdWithRevitCategory).revitCategory !== undefined) {
      return GEOMETRIC_PROPERTY_TO_ALGORITHM_MAP.get(
        (dbId as DbIdWithRevitCategory).revitCategory
      )?.get(geometricPropertyOrAlgorithm);
    } else {
      console.error(
        'dbId does not contain a valid IFC class or Revit category'
      );
      return undefined;
    }
  } else {
    return geometricPropertyOrAlgorithm;
  }
}

function getAlgorithmTypeForShape(
  geometricPropertyOrAlgorithm: GeometricProperty | GeometricAlgorithmType,
  shape: ShapeDeepFragment
): GeometricAlgorithmType | undefined {
  if (isGeometricProperty(geometricPropertyOrAlgorithm)) {
    if (shape.polygon) {
      return GEOMETRIC_PROPERTY_TO_ALGORITHM_MAP.get(
        CustomElementClass.PolygonShape
      )?.get(geometricPropertyOrAlgorithm);
    } else if (shape.extrudedPolygon) {
      return GEOMETRIC_PROPERTY_TO_ALGORITHM_MAP.get(
        CustomElementClass.ExtrudedPolygonShape
      )?.get(geometricPropertyOrAlgorithm);
    } else if (shape.line) {
      return GEOMETRIC_PROPERTY_TO_ALGORITHM_MAP.get(
        CustomElementClass.LineShape
      )?.get(geometricPropertyOrAlgorithm);
    }
  } else {
    return geometricPropertyOrAlgorithm;
  }
}

function getAlgorithmTypeForSheetShape(
  geometricPropertyOrAlgorithm: GeometricProperty | GeometricAlgorithmType,
  shape: SheetShapeDeepFragment
): GeometricAlgorithmType | undefined {
  if (isGeometricProperty(geometricPropertyOrAlgorithm)) {
    if (shape.sheetShapePolygon) {
      return GEOMETRIC_PROPERTY_TO_ALGORITHM_MAP.get(
        CustomElementClass.Polygon2Shape
      )?.get(geometricPropertyOrAlgorithm);
    } else if (shape.sheetShapeLine) {
      return GEOMETRIC_PROPERTY_TO_ALGORITHM_MAP.get(
        CustomElementClass.Line2Shape
      )?.get(geometricPropertyOrAlgorithm);
    }
  } else {
    return geometricPropertyOrAlgorithm;
  }
}

/* This returns an Observable of geometric results for each dbId,
  this allows us to stream the results as they come in, instead of waiting for all of them to finish, which
  can cause the application to become unresponsive */
export const calculateGeometry$ = (
  geometricPropertyOrAlgorithm: GeometricProperty | MeshAlgorithmType,
  modelDbIds: ElementIdentifierDbidType[],
  propertyContext: PropertyContext,
  maxTrianglesForComplexAlgorithm: number
): Source<OperationResult> => {
  const {
    loadedModels,
    loadedShapes,
    loadedSheetShapes,
    loadedSparkelProperties,
  } = propertyContext;
  const { bimElementDbIds, shapeDbIds, sheetShapeDbIds } = splitDbIdsByType(
    modelDbIds,
    loadedShapes,
    loadedModels,
    loadedSheetShapes
  );

  const standardElementCalculations$ = pipe(
    fromArray(bimElementDbIds),
    mergeMap((modelDbId) =>
      pipe(
        getCategories$(
          propertyContext.loadedModels,
          modelDbId.modelUrn,
          modelDbId.dbIds
        ),
        mergeMap((dbId) => {
          const algorithmType: GeometricAlgorithmType | undefined =
            getAlgorithmTypeForBimElement(geometricPropertyOrAlgorithm, dbId);

          if (!algorithmType) {
            return empty;
          }

          const model = propertyContext.loadedModels[dbId.modelUrn];
          return fromPromise(
            calculateGeometricAlgorithmForMeshElement(
              algorithmType,
              dbId,
              model,
              maxTrianglesForComplexAlgorithm
            )
          );
        })
      )
    )
  );

  const shapeCalculations$: Source<OperationResult> = pipe(
    fromArray(shapeDbIds),
    mergeMap((dbId) => {
      const shapes = propertyContext.loadedShapes[dbId.modelUrn]?.filter(
        (shape) => dbId.dbIds.includes(shape.dbId)
      );
      if (shapes === undefined) {
        return empty;
      }

      return pipe(
        fromArray(shapes),
        map((shape) => {
          const algorithmType = getAlgorithmTypeForShape(
            geometricPropertyOrAlgorithm,
            shape
          );
          if (!algorithmType) {
            return undefined;
          }
          return calculateGeometricAlgorithmForShape(
            algorithmType,
            dbId,
            shape
          );
        })
      );
    })
  );

  const sheetShapeCalculations$: Source<OperationResult> = pipe(
    fromArray(sheetShapeDbIds),
    mergeMap((dbId) => {
      const shapes = propertyContext.loadedSheetShapes[dbId.modelUrn]?.filter(
        (shape) => dbId.dbIds.includes(shape.dbId)
      );
      if (shapes === undefined) {
        return empty;
      }
      const externalShapes = loadedSheetShapes[dbId.modelUrn];

      return pipe(
        fromArray(shapes),
        map((shape) => {
          const algorithmType = getAlgorithmTypeForSheetShape(
            geometricPropertyOrAlgorithm,
            shape
          );
          const calibration = propertyContext.loadedSheetCalibrations[
            shape.sheetId
          ]?.find(
            (calibration) => calibration.pageNumber === shape.sheetPageNumber
          )?.calibration;

          if (!algorithmType || !calibration) {
            return undefined;
          }
          return calculateGeometricAlgorithmForSheetShape(
            algorithmType,
            dbId,
            shape,
            calibration,
            loadedSparkelProperties,
            externalShapes
          );
        })
      );
    })
  );

  return merge([
    standardElementCalculations$,
    shapeCalculations$,
    sheetShapeCalculations$,
  ]);
};

const getIfcClasses$ = (
  models: ModelsMap,
  modelUrn: string,
  dbIds: number[]
): Source<DbIdWithIfcClass> => {
  if (dbIds.length === 0) {
    // Because forge returns properties for all elements when no dbIds are specified
    return empty;
  }
  return make((observer) => {
    let cancelled = false;

    const model = models[modelUrn];
    model.getBulkProperties2(
      dbIds,
      {
        propFilter: ['IfcClass'],
        categoryFilter: ['Element'],
        ignoreHidden: true,
      },
      (properties: Autodesk.Viewing.PropertyResult[]) => {
        if (cancelled) {
          return;
        }
        for (const property of properties) {
          if (cancelled) {
            break;
          }
          if (property.properties.length === 0) {
            continue;
          }
          const ifcClass = property.properties[0].displayValue as IfcClass;
          observer.next({
            modelUrn: modelUrn,
            dbId: property.dbId,
            ifcClass: ifcClass,
          });
        }
        observer.complete();
      },
      () => observer.complete()
    );

    return () => {
      cancelled = true;
    };
  });
};

type DbIdWithRevitCategory = {
  dbId: number;
  modelUrn: string;
  revitCategory: RevitCategory;
};

const getRevitCategories$ = (
  models: ModelsMap,
  modelUrn: string,
  dbIds: number[]
): Source<DbIdWithRevitCategory> => {
  if (dbIds.length === 0) {
    // Because forge returns properties for all elements when no dbIds are specified
    return empty;
  }
  return make((observer) => {
    let cancelled = false;

    const model = models[modelUrn];
    model.getBulkProperties2(
      dbIds,
      {
        propFilter: ['Category'],
        categoryFilter: ['__category__'],
        ignoreHidden: false,
      },
      (properties: Autodesk.Viewing.PropertyResult[]) => {
        if (cancelled) {
          return;
        }
        for (const property of properties) {
          if (cancelled) {
            break;
          }
          if (property.properties.length === 0) {
            continue;
          }
          const revitCategory = property.properties[0]
            .displayValue as RevitCategory;
          observer.next({
            modelUrn: modelUrn,
            dbId: property.dbId,
            revitCategory,
          });
        }
        observer.complete();
      },
      () => observer.complete()
    );

    return () => {
      cancelled = true;
    };
  });
};

const getCategories$ = (
  models: ModelsMap,
  modelUrn: string,
  dbIds: number[]
): Source<DbIdWithIfcClass | DbIdWithRevitCategory> => {
  return merge<DbIdWithIfcClass | DbIdWithRevitCategory>([
    getIfcClasses$(models, modelUrn, dbIds),
    getRevitCategories$(models, modelUrn, dbIds),
  ]);
};

type DbIdWithIfcClass = {
  dbId: number;
  modelUrn: string;
  ifcClass: IfcClass;
};

export async function getCategories(
  models: ModelsMap,
  modelDbIds: ElementIdentifierDbidType[]
): Promise<Array<DbIdWithIfcClass | DbIdWithRevitCategory>> {
  const categories: Array<DbIdWithIfcClass | DbIdWithRevitCategory> = [];
  for (const { modelUrn, dbIds } of modelDbIds) {
    if (dbIds.length === 0) {
      // Because forge returns properties for all elements when no dbIds are specified
      continue;
    }
    const model = models[modelUrn];
    const properties: Autodesk.Viewing.PropertyResult[] = await new Promise(
      (resolve, reject) => {
        model.getBulkProperties2(
          dbIds,
          {
            propFilter: ['IfcClass', 'Category'],
            categoryFilter: ['Element', '__category__'],
            ignoreHidden: false,
          },
          resolve,
          () => void reject('Failed to get properties')
        );
      }
    );
    properties.forEach((property) => {
      const ifcClass = property.properties.find(
        (prop) => prop.attributeName === 'IfcClass'
      )?.displayValue;

      if (ifcClass) {
        categories.push({
          dbId: property.dbId,
          modelUrn: modelUrn,
          ifcClass,
        } as DbIdWithIfcClass);
      } else {
        const revitCategory = property.properties.find(
          (prop) => prop.attributeName === 'Category'
        )?.displayValue;

        if (revitCategory) {
          categories.push({
            dbId: property.dbId,
            modelUrn: modelUrn,
            revitCategory: revitCategory,
          } as DbIdWithRevitCategory);
        }
      }
    });
  }

  return categories;
}

function toOperationResult(
  result: GeometricResult,
  context: OperationContext
): OperationResult {
  if (result === undefined) {
    return [undefined, context];
  }
  const dimensions = dimensionsForResultType[result.type];
  const { value, units } = getScaledValue(result.value, context, dimensions);
  return [
    {
      ...result,
      value: value,
      units,
    },
    context,
  ];
}
